import { graphql } from 'gatsby';
import React from 'react';

import Post from '../components/Post';

const post = ({ data }) => {
  return <Post {...data.sanityPost} />;
};

export const PostQuery = graphql`
  query postQuery($id: String!) {
    sanityPost(id: { eq: $id }) {
      title
      content: _rawBody(resolveReferences: { maxDepth: 15 })
      mainImage {
        alt
        asset {
          _id
          metadata {
            lqip
            dimensions {
              aspectRatio
            }
          }
        }
        crop {
          top
          left
          bottom
          right
        }
        hotspot {
          x
          y
          height
          width
        }
      }
      seoOptions {
        metaDescription
        title
      }
    }
  }
`;

export default post;
